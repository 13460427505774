<template>
    <apexchart
        class="apex-charts"
        height="300"
        type="line"
        :series="series"
        :options="options"
    />
</template>

<script>
    import moment from 'moment'

  export default {
    name: "AverageWorkingTimeChart",
    props: {
      seriesProp: {
        required: true,
        type: Array,
      },
      categoriesSeriesProp: {
        required: true,
        type: Array,
      },
    },
    data() {
      return {}
    },
    computed: {
      series() {
        return [{
          name: this.getI18nModified({prefix: "COMMON", suffix: "hours", modifier: 2}),
          data: this.seriesProp
        }]
      },
      options() {
        return {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
            toolbar: {
            show: false
            }
          },
          dataLabels: {
            enabled: true,
            style: {
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['#494040']
            },
            formatter: function (value, opts) {
            return moment().startOf('day').add(value, 'minutes').format('HH:mm')
            },
          },
          tooltip: {
            y: {
              formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                return moment().startOf('day').add(value, 'minutes').format('HH:mm')
              }
            }
          },
          stroke: {
            curve: 'straight'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'],
              opacity: 0.5
            },
          },
          xaxis: {
            categories: this.categoriesSeriesProp
          }
        }
      }
    }

  }
</script>

<style scoped>

</style>
