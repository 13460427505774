<template>
    <div class="card card-pdv">
        <PageHeader :title="i18nAverageTime" />
        <b-skeleton-img v-if="loading" />
        <apexchart
            v-else
            class="apex-charts"
            height="260"
            type="line"
            :series="series"
            :options="options"
        />
    </div>
</template>

<script>
import PageHeader from '@/src/components/PageHeader.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import AverageWorkingService from '@src/services/dashboards/AverageWorkingService'
import { orderBy } from 'lodash'
import moment from 'moment'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        PageHeader,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        vuetableFormatter,
    ],
    data() {
        return {
            i18nKey: i18nKey,
            loading: true,
            averageWorkingTimeInfo: {
                categories: [],
                working_time: [],
                pos_time: [],
                commuting_time: [],
            },
        }
    },
    validations: {},
    computed: {
        i18nAverageTime() {
            return this.getI18n(i18nKey, 'TITLES.average_time')
        },
        i18nPosTime() {
            return this.getI18n(i18nKey, 'TITLES.pos_time')
        },
        i18nWorkingTime() {
            return this.getI18n(i18nKey, 'TITLES.working_time')
        },
        i18nDisplacementTime() {
            return this.getI18n(i18nKey, 'TITLES.displacement_time')
        },
      options() {
        return {
          chart: {
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#0275d8', '#5cb85c', '#f0ad4e'],
          dataLabels:
            {
              enabled: true,
              style: {
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['#494040']
              },
              formatter: function (value, opts) {
                return moment().startOf('day').add(value, 'minutes').format('HH:mm')
              },
            },
          tooltip: {
            y: {
              formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                return moment().startOf('day').add(value, 'minutes').format('HH:mm')
              }
            }
          },
          stroke: {
            width: [3, 3, 4],
            curve: 'smooth',
          },
          grid: {
            row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.2
            },
            borderColor: '#f1f3fa'
          },
          markers: {
            style: 'inverted',
            size: 6,
          },
          xaxis: {
            categories: this.averageWorkingTimeInfo.categories,
          },
          min: 5, max: 40,
          responsive: [
            {breakpoint: 600, options: {chart: {toolbar: {show: false}}, legend: {show: false}}}
          ],
        }
      },
        series() {
            return [
                {
                    name: this.i18nWorkingTime,
                    data: this.averageWorkingTimeInfo.working_time,
                },
                {
                    name: this.i18nPosTime,
                    data: this.averageWorkingTimeInfo.pos_time,
                },
                {
                    name: this.i18nDisplacementTime,
                    data: this.averageWorkingTimeInfo.commuting_time,
                }
            ]
        }
    },
    methods: {
        refreshChart(parameters) {
            this.averageWorkingFetch(parameters)
        },
        averageWorkingFetch(parameters) {
            this.loading = true
            AverageWorkingService.fetchAll(parameters)
                .then((response) => {
                    this.resetAverageChart()
                    const keys = orderBy(Object.keys(response.data.data), (value) => { return moment(value, 'YYYY-MM-DD').toDate() });
                    keys.forEach((key) => {
                        this.averageWorkingTimeInfo.categories.push(this.formatDateShort(key))
                        this.averageWorkingTimeInfo.working_time.push(response.data.data[key].total_working_time)
                        this.averageWorkingTimeInfo.pos_time.push(response.data.data[key].total_time)
                        this.averageWorkingTimeInfo.commuting_time.push(response.data.data[key].total_displacement)
                    })
                })
                .catch((err) => {
                    return err
                })
                .finally(() => {
                  this.loading = false
                })
        },
        resetAverageChart() {
            Object.assign(this.averageWorkingTimeInfo, {
                categories: [],
                working_time: [],
                pos_time: [],
                commuting_time: [],
            })
        },
    },
}
</script>
