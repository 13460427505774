<template>
    <div>
        <b-row>
            <b-col md="6">
                <operational-workschedule-indicator-card
                    ref="chartAssignmentsPlanned"
                    :title="i18nAssignmentsPlanned"
                    :busy="busy"
                    :value="widgetData.assignments_planned"
                    :percentage="percAssignmentsPlanned"
                />
            </b-col>
            <b-col md="6">
                <operational-workschedule-indicator-card
                    ref="chartCompletedAssignments"
                    :title="i18nCompletedAssignments"
                    :busy="busy"
                    :value="widgetData.completed_assignments"
                    :percentage="percCompletedAssignments"
                    text-color="#89E09C"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6">
                <operational-workschedule-indicator-card
                    ref="chartJustifiedAssignments"
                    :title="i18JustifiedAssignments"
                    :busy="busy"
                    :value="widgetData.justified_assignments"
                    :percentage="percJustifiedAssignments"
                    text-color="#EDB012"
                />
            </b-col>
            <b-col md="6">
                <operational-workschedule-indicator-card
                    ref="charPosNotVisited"
                    :title="i18nPosNotVisited"
                    :busy="busy"
                    :value="widgetData.uncompleted_assignments"
                    :percentage="percUncompletedAssignments"
                    text-color="#CE0404"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import OperationalWorkscheduleIndicatorCard from '@/src/components/dashboards/OperationalWorkscheduleIndicatorCard'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        OperationalWorkscheduleIndicatorCard,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    props: {
        busy: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            widgetData: {
                total_users: 0,
                working_users: 0,
                completed_assignments: 0,
                unplanned_users: 0,
                not_working_users: 0,
                justified_absence: 0,
                assignments_planned: 0,
                uncompleted_assignments: 0,
                planned_users: 0,
                justified_assignments: 0,
            },

            percAssignmentsPlanned: 0,
            percCompletedAssignments: 0,
            percJustifiedAssignments: 0,
            percUncompletedAssignments: 0,
        }
    },
    validations: {},
    computed: {
        i18nAssignmentsPlanned() {
            return this.getI18nModified({
                prefix: i18nKey,
                name: 'assignments_planned',
                suffix: 'TITLES.assignments_planned',
                modifier: 2,
            })
        },
        i18nCompletedAssignments() {
            return this.getI18n(i18nKey, 'TITLES.completed_assignments')
        },
        i18JustifiedAssignments() {
            return this.getI18n(i18nKey, 'TITLES.justified_assignments')
        },
        i18nPosNotVisited() {
            return this.getI18n(i18nKey, 'TITLES.pos_not_visited')
        },
    },
    methods: {
        refreshChart(data) {
            this.widgetData = data

            this.percAssignmentsPlanned = Math.round((this.widgetData.completed_assignments / (this.widgetData.assignments_planned > 0 ? this.widgetData.assignments_planned : 1)) * 100)
            this.percCompletedAssignments = Math.round((this.widgetData.completed_assignments / (this.widgetData.assignments_planned > 0 ? this.widgetData.assignments_planned : 1)) * 100)
            this.percJustifiedAssignments = Math.round((this.widgetData.justified_assignments / (this.widgetData.assignments_planned > 0 ? this.widgetData.assignments_planned : 1)) * 100)
            this.percUncompletedAssignments = Math.round((this.widgetData.uncompleted_assignments / (this.widgetData.assignments_planned > 0 ? this.widgetData.assignments_planned : 1)) * 100)
        },
    },
}
</script>
