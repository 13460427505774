<template>
    <b-row>
        <b-col md="4">
            <indicator-card
                ref="chartPlannedUsers"
                :title="i18nPlannedUsers"
                :busy="busy"
                :value="widgetData.planned_users"
                :total-records="widgetData.total_users"
                :show-total-records="true"
            />
        </b-col>
        <b-col md="4">
            <indicator-card
                ref="chartWorkingUsers"
                :title="i18nWorkingUsers"
                :busy="busy"
                :value="widgetData.working_users"
                :total-records="widgetData.total_users"
                :show-total-records="true"
                text-color="#89E09C"
            />
        </b-col>
        <b-col md="4">
            <indicator-card
                ref="chartNotWorkingUsers"
                :title="i18NotWorkingUsers"
                :busy="busy"
                :value="widgetData.not_working_users"
                :total-records="widgetData.total_users"
                :show-total-records="true"
                text-color="#CE0404"
            />
        </b-col>
    </b-row>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import IndicatorCard from '@/src/components/dashboards/IndicatorCard'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        IndicatorCard,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    props: {
        busy: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            widgetData: {
                total_users: 0,
                working_users: 0,
                completed_assignments: 0,
                unplanned_users: 0,
                not_working_users: 0,
                justified_absence: 0,
                assignments_planned: 0,
                uncompleted_assignments: 0,
                planned_users: 0,
                justified_assignments: 0,
            },
        }
    },
    validations: {},
    computed: {
        i18nPlannedUsers() {
            return this.getI18n(i18nKey, 'TITLES.planned_users')
        },
        i18nWorkingUsers() {
            return this.getI18n(i18nKey, 'TITLES.working_users')
        },
        i18NotWorkingUsers() {
            return this.getI18n(i18nKey, 'TITLES.not_working_users')
        },
    },
    methods: {
        refreshChart(data) {
            this.widgetData = data
        },
    },
}
</script>
