<template>
  <apexchart
    class="apex-charts"
    height="300"
    type="line"
    :series="series"
    :options="options"
  ></apexchart>
</template>
<script>

  export default {
    name: "PlannedCompletedJustifiedChart",
    props: {
      plannedProp: {
        required: true,
        type: Array,
      },
      checkedProp: {
        required: true,
        type: Array,
      },
      justifiedProp: {
        required: true,
        type: Array,
      },
      categoriesSeriesProp: {
        required: true,
        type: Array,
      },
    },
    data() {
      return {}
    },
    computed: {
      options() {
        return {
          chart: {
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#0275d8', '#5cb85c', '#f0ad4e'],
          dataLabels:
            {
              enabled: true,
              style: {
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['#494040']
              },
            },
          stroke: {
            width: [3, 3, 4],
            curve: 'smooth'
          },
          grid: {
            row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.2
            },
            borderColor: '#f1f3fa'
          },
          markers: {
            style: 'inverted',
            size: 6,
          },
          xaxis: {
            categories: this.categoriesSeriesProp,
          },
          min: 5, max: 40,
          responsive: [
            {breakpoint: 600, options: {chart: {toolbar: {show: false}}, legend: {show: false}}}
          ],
        }
      },
      series() {
        return [{
          name: this.getI18n('DASHBOARDS', 'TITLES.planned'),
          data: this.plannedProp
        },
          {
            name: this.getI18n('DASHBOARDS', 'TITLES.checked'),
            data: this.checkedProp
          },
          {
            name: this.getI18n('DASHBOARDS', 'TITLES.justified'),
            data: this.justifiedProp
          }
        ]
      }
    },
  }
</script>

<style scoped>

</style>
