import BaseService from '@src/services/BaseService'

class PlannedCompletedJustifiedService extends BaseService {
    constructor() {
        super('dashboards/widgets/planned-completed-justified')
    }
}

export default new PlannedCompletedJustifiedService()

