<template>
    <div class="card card-pdv">
        <PageHeader :title="title" />
        <div class="card-body">
            <b-row v-if="busy">
                <b-col md="12">
                    <b-skeleton type="button" width="100%"></b-skeleton>
                    <b-skeleton class="mt-1"></b-skeleton>
                    <b-skeleton class="mt-1"></b-skeleton>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col md="12" class="text-center">
                    <label class="main-text" :style="mainTextStyle">{{ padLeft(value) }}</label>
                    <div class="progress">
                        <b-progress-bar
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            :style="`background-color: ${textColor}; width: ${percentage}%`"
                            class="progress-pdv">
                        </b-progress-bar>
                    </div>
                    <div class="text-right">
                        {{ getI18n('PAGINATION', 'total') }}: {{ percentage }}%
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import PageHeader from '@/src/components/PageHeader.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'

export default {
    components: {
        PageHeader,
    },
    mixins: [
        vuetableFormatters,
    ],
    props: {
        title: {
            type: String,
            required: true,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        value: {
            type: Number,
            required: false,
            default: 0,
        },
        totalRecords: {
            type: Number,
            required: false,
            default: 0,
        },
        textColor: {
            type: String,
            required: false,
            default: '#2E7FEB',
        },
        percentage: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data () {
      return {}
    },
    computed: {
        mainTextStyle() {
            return {
                '--color': this.textColor
            }
        },
    },
    methods: {
        showChart() {
        }
    }
}
</script>
<style>
.main-text {
    color: var(--color);
    font-family: 'Exo2-Bold', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 120%;
}
</style>
