import BaseService from '@src/services/BaseService'

class AverageWorkingService extends BaseService {
    constructor() {
        super('dashboards/average-user-time')
    }
}

export default new AverageWorkingService()

