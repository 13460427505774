<script>
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import ResumeService from '@src/services/dashboards/ResumeService'
import Shared from '@src/mixins/shared.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService'
import averageTimeChart from '@/src/router/views/dashboards/operational/averageTimeChart.vue'
import operationalResume from '@/src/router/views/dashboards/operational/operationalResume.vue'
import operationalWorkscheduleResume from '@/src/router/views/dashboards/operational/operationalWorkscheduleResume.vue'
import plannedCompletedJustifiedChart from '@/src/router/views/dashboards/operational/plannedCompletedJustifiedChart.vue'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import FilterButton from '@/src/components/FilterButton'
import operationalFilterModal from '@/src/router/views/dashboards/operational/operationalFilterModal.vue'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        Breadcrumb,
        DateRangePicker,
        multiSelectWithService,
        averageTimeChart,
        operationalResume,
        operationalWorkscheduleResume,
        plannedCompletedJustifiedChart,
        MoreFiltersLabelButton,
        FilterButton,
        operationalFilterModal,
    },
    mixins: [filterVuetable, Shared],
    data() {
        return {
            title: this.getI18n(i18nKey, 'TITLES.operational'),
            loadingResume: true,
            operationalParameters: {
                start: moment().startOf('day').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD'),
                role_id: this.$store.state.auth.defaultDashboardRole ? [this.$store.state.auth.defaultDashboardRole.id] : [],
            },
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            filters: {
                date: {
                    start: moment().startOf('day').toDate(),
                    end: moment().startOf('day').toDate()
                },
                business_units: [],
                roles: [],
                users: [],
                regionals: [],
                teams: [],
                chains: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18n(i18nKey, 'TITLES.operational'),
                    active: true,
                },
            ]
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nRegional() {
            return this.getI18n('POS', 'TITLES.regional')
        },
    },
    mounted() {
        if (this.$store.state.auth.defaultDashboardRole) {
            this.filters.roles = [this.$store.state.auth.defaultDashboardRole]
        }
        this.resumeFetch()
        this.$refs.plannedCompletedJustifiedChart.refreshChart(this.operationalParameters)
        this.$refs.averageTimeChart.refreshChart(this.operationalParameters)
    },
    methods: {
        createResumeParameters() {
            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                business_unit_id: this.filters.business_units.map((businessUnit) => businessUnit.id),
                role_id: this.filters.roles.map((role) => role.id),
                user_id: this.filters.users.map((user) => user.id),
                regional_id: this.filters.regionals.map((regional) => regional.id),
                team_id: this.filters.teams.map((team) => team.id),
                chain_id: this.filters.chains.map((chain) => chain.id),
                start: period.from,
                end: period.to,
            })
        },
        resumeFetch() {
            this.loadingResume = true
            ResumeService.fetchAll(this.operationalParameters)
                .then((response) => {
                    const widgetData = response.data.data

                    this.$refs.operationalResume.refreshChart(widgetData)
                    this.$refs.operationalWorkscheduleResume.refreshChart(widgetData)

                    this.loadingResume = false

                    if (this.filters.regionals.length <= 0) {
                        this.$refs.regionalMultiselect.refresh()
                    }
                })
                .catch((err) => {
                    this.loadingResume = false
                    return err
                })
        },
        filter(appliedFilters) {
            if (appliedFilters) {
                this.filters = appliedFilters
            }
            this.operationalParameters = this.createResumeParameters()
            this.resumeFetch()
            this.$refs.plannedCompletedJustifiedChart.refreshChart(this.operationalParameters)
            this.$refs.averageTimeChart.refreshChart(this.operationalParameters)
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.operationalFilterModal.showModal(this.filters))
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nPeriod"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-range-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disabled="loadingResume"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nRegional"
                                class="label-pdv"
                                label-for="filter-regional"
                            >
                                <multiSelectWithService
                                    :id="'filter-regional'"
                                    ref="regionalMultiselect"
                                    v-model="filters.regionals"
                                    :service="'pos-regionals'"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="filterParameters"
                                    :disabled="loadingResume"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" :disabled="loadingResume" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" :disabled="loadingResume" @onClick="filter" />
                    </div>
                </div>
            </div>
        </div>

        <operational-resume ref="operationalResume" :busy="loadingResume" />

        <planned-completed-justified-chart ref="plannedCompletedJustifiedChart" />

        <b-row>
            <b-col md="6">
                <operational-workschedule-resume ref="operationalWorkscheduleResume" :busy="loadingResume" />
            </b-col>
            <b-col md="6">
                <average-time-chart ref="averageTimeChart" />
            </b-col>
        </b-row>

        <operational-filter-modal
            ref="operationalFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
