<template>
    <b-row>
        <div class="col-xl-6">
            <div class="card card-pdv">
                <PageHeader :title="i18nPlannedCompletedJustifiedChart" />
                <b-skeleton-img v-if="loadingAverageWorking" height="300px" />
                <planned-completed-justified-chart
                    v-else
                    :justified-prop="plannedCompletedJustifiedInfo.justified_assignments"
                    :planned-prop="plannedCompletedJustifiedInfo.assignments_planned"
                    :checked-prop="plannedCompletedJustifiedInfo.completed_assignments"
                    :categories-series-prop="plannedCompletedJustifiedInfo.categories"
                />
            </div>
        </div>

        <div class="col-xl-6">
            <div class="card card-pdv">
                <PageHeader :title="i18nAverageWorkingTimeChart" />
                <b-skeleton-img v-if="loadingPlannedCompletedJustified" height="300px" />
                <average-working-time-chart
                    v-else
                    :series-prop="averageWorkingTimeInfo.arr_total_with_breaks"
                    :categories-series-prop="averageWorkingTimeInfo.categories"
                />
            </div>
        </div>
    </b-row>
</template>

<script>
import PageHeader from '@/src/components/PageHeader.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import plannedCompletedJustifiedChart from '@views/dashboards/charts/plannedCompletedJustifiedChart'
import averageWorkingTimeChart from '@views/dashboards/charts/averageWorkingTimeChart'
import AverageWorkingService from '@src/services/dashboards/AverageWorkingService'
import PlannedCompletedJustifiedService from '@src/services/dashboards/PlannedCompletedJustifiedService'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import { orderBy } from 'lodash'
import moment from 'moment'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        PageHeader,
        averageWorkingTimeChart,
        plannedCompletedJustifiedChart,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        vuetableFormatter,
    ],
    data() {
        return {
            i18nKey: i18nKey,
            loadingAverageWorking: true,
            loadingPlannedCompletedJustified: true,
            averageWorkingTimeInfo: {
                categories: [],
                arr_total_with_breaks: [],
                arr_total_without_breaks: [],
            },
            plannedCompletedJustifiedInfo: {
                categories: [],
                assignments_planned: [],
                justified_assignments: [],
                completed_assignments: [],
            },
        }
    },
    validations: {},
    computed: {
        i18nPlannedCompletedJustifiedChart() {
            return this.getI18n(i18nKey, 'CHARTS.plannedCompletedJustifiedChart')
        },
        i18nAverageWorkingTimeChart() {
            return this.getI18n(i18nKey, 'CHARTS.averageWorkingTimeChart')
        },
    },
    methods: {
        refreshChart(parameters) {
            this.averageWorkingFetch(parameters)
            this.plannedCompletedJustifiedFetch(parameters)
        },
        averageWorkingFetch(parameters) {
            this.loadingAverageWorking = true
            AverageWorkingService.fetchAll(parameters)
                .then((response) => {
                    this.resetAverageChart()
                    const keys = orderBy(Object.keys(response.data.data), (value) => { return moment(value, 'YYYY-MM-DD').toDate() });
                    keys.forEach((key) => {
                        this.averageWorkingTimeInfo.categories.push(this.formatDateShort(key))
                        this.averageWorkingTimeInfo.arr_total_with_breaks.push(response.data.data[key].total_working_time)
                        this.averageWorkingTimeInfo.arr_total_without_breaks.push(response.data.data[key].total_time)
                    })
                })
                .catch((err) => {
                    return err
                })
                .finally(() => {
                  this.loadingAverageWorking = false
                })
        },

        plannedCompletedJustifiedFetch(parameters) {
            this.loadingPlannedCompletedJustified = true
            PlannedCompletedJustifiedService.fetchAll(parameters)
                .then((response) => {
                    this.resetPlannedCharts()
                    const keys = orderBy(Object.keys(response.data.data), (value) => { return moment(value, 'YYYY-MM-DD').toDate() });
                    keys.forEach((key) => {
                        this.plannedCompletedJustifiedInfo.assignments_planned.push(
                            response.data.data[key].planned
                        )
                        this.plannedCompletedJustifiedInfo.justified_assignments.push(
                            response.data.data[key].justified
                        )
                        this.plannedCompletedJustifiedInfo.completed_assignments.push(
                            response.data.data[key].completed
                        )
                        this.plannedCompletedJustifiedInfo.categories.push(
                            this.formatDateShort(key)
                        )
                    })
                })
                .catch((err) => {
                    return err
                })
                .finally(() => {
                  this.loadingPlannedCompletedJustified = false
                })
        },
        resetPlannedCharts() {
            Object.assign(this.plannedCompletedJustifiedInfo, {
                categories: [],
                assignments_planned: [],
                justified_assignments: [],
                completed_assignments: [],
            })
        },
        resetAverageChart() {
            Object.assign(this.averageWorkingTimeInfo, {
                categories: [],
                arr_total_with_breaks: [],
                arr_total_without_breaks: [],
            })
        },
    },
}
</script>
