<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        size="lg"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nPeriod"
                            class="required label-pdv"
                            label-for="filter-date"
                        >
                            <date-range-picker
                                :value.sync="filterModal.date"
                                :max-date="new Date()"
                                :disable-clean-button="true"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nRegional"
                            class="label-pdv"
                            label-for="filter-regional"
                        >
                            <multiSelectWithService
                                :id="'filter-regional'"
                                ref="regionalMultiselect"
                                v-model="filterModal.regionals"
                                :service="'pos-regionals'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nChain"
                            label-for="filter-chain"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-chain'"
                                ref="chainMultiselect"
                                v-model="filterModal.chains"
                                :service="'pos-chains'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nBusinessUnit"
                            label-for="filter-business-unit"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="filterModal.business_units"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="true"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nTeam"
                            label-for="filter-team"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-team'"
                                ref="teamFilter"
                                v-model="filterModal.teams"
                                :service="'teams'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nUser"
                            label-for="filter-user"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-user'"
                                ref="userMultiselect"
                                v-model="filterModal.users"
                                :service="'users'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="usersParameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nRole"
                            class="label-pdv"
                            label-for="filter-role"
                        >
                            <multiSelectWithService
                                :id="'filter-role'"
                                ref="roleMultiselect"
                                v-model="filterModal.roles"
                                :service="'roles'"
                                label="alias"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import moment from 'moment';

const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        SaveButton,
        ClearFilterButton,
        DateRangePicker,
        multiSelectWithService,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nCommon: i18nCommon,
            filterModal: {},
            submitLoading: false,
            parameters: {
                with_admin: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {},
    computed: {
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nRegional() {
            return this.getI18n('POS', 'TITLES.regional')
        },
        i18nChain() {
            return this.getI18n('POS', 'TITLES.chain')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nTeam() {
            return this.getI18n('TEAMS', 'TITLES.team')
        },
        i18nRole() {
            return this.getI18n('SECURITY', 'TITLES.role')
        },
    },
    methods: {
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                date: {
                    start: moment().startOf('day').toDate(),
                    end: moment().startOf('day').toDate()
                },
                business_units: [],
                roles: [],
                users: [],
                regionals: [],
                teams: [],
                chains: [],
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
